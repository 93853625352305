import React, { useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, makeStyles, WithStyles } from '@material-ui/core/styles';
import api from '../../../../services/api'
import { bindRemoteError } from '../../../../utils'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import {
    CircularProgress,
    Backdrop,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';

import { Prova } from "../../../../models/academic";
import { EventEmitter } from 'events';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        backdrop: {
            zIndex: 10000,
            color: '#fff',
        },
    }),
);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });


interface IAvaliarProva {
    show: boolean,
    onSuccess(): Promise<void>,
    hidden(): void,
    prova: Prova,
    nomeInstituicao?: string
}



export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


type Error = {
    resultado?: string,
    pontos?: string,
    file?: string,
    motivo_reprovacao_id?: string,
}

const AvaliarProva: React.FC<IAvaliarProva> = ({ show, onSuccess, prova, hidden, nomeInstituicao }) => {

    const classes = useStyles();
    const [resultado, setResultado] = useState<string | undefined | unknown>('')
    const [motivo_reprovacao_id, setMotivo_reprovacao_id] = useState<number | undefined | unknown>(null)
    const [pontos, setPontos] = useState<string | undefined>('')
    const [file, setFile] = useState<File | undefined>()
    const [erros, setErros] = useState<Error>({} as Error)
    const [saving, setSaving] = useState<boolean>(false)
    const [alert, setAlert] = useState<string>('')

    const [motivosReprovacao, setMotivosReprovacao] = useState([])

    useEffect(() => {
        if (!prova.id) {
            setResultado(undefined)
            setPontos(undefined)
            setFile(undefined)
            setMotivo_reprovacao_id(undefined)
            setMotivosReprovacao([])
        } else {
            setResultado(prova?.resultado)
            setMotivo_reprovacao_id(prova?.motivo_reprovacao_id ? prova.motivo_reprovacao_id : undefined)
            setPontos(prova?.pontos ? prova.pontos + '' : undefined)
        }
        setErros({})
        setAlert('')
    }, [prova])

    useEffect(() => {
        setErros({
            ...erros,
            pontos: undefined
        })
    }, [pontos])


    useEffect(() => {
        setErros({
            ...erros,
            file: undefined
        })
    }, [file])

    useEffect(() => {
        setErros({
            ...erros,
            resultado: undefined
        })
        if (resultado && resultado == 'R') {
            loadMotivosReprovacao()
        } else {
            setMotivo_reprovacao_id(undefined)
        }
    }, [resultado])


    useEffect(() => {
        setErros({
            ...erros,
            motivo_reprovacao_id: undefined
        })
    }, [motivo_reprovacao_id])


    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList
        if ((files?.length as number) >= 0) setFile(files[0] as File)
    }

    async function loadMotivosReprovacao() {
        try {
            const response = await api.get(`/motivo_reprovacao`)
            setMotivosReprovacao(response.data)
        } catch (error) {
            console.log(error)
            setAlert('Não foi possível carregar Motivos Reprovação')
        }
    }

    async function handleSave() {
        setSaving(true)
        try {
            setErros({})
            setAlert('')
            const formData = new FormData()
            if (pontos) formData.append('pontos', pontos + "")
            if (file) formData.append('file', file as File)

            if (resultado) {
                formData.append('resultado', resultado as string)
                if (resultado == 'R') {
                    formData.append('motivo_reprovacao_id', motivo_reprovacao_id + "")
                } else {
                    formData.append('motivo_reprovacao_id', '0')
                }
            }


            await api.post(`/provas/${prova.id}/correcoes`, formData, {
                headers: {
                    "Content-Type": `multipart/form-data;`
                }
            })
            hidden()
            await onSuccess()
        } catch (error) {
            //@ts-ignore
            if (error.response && error.response.data) {
                //@ts-ignore
                setErros(bindRemoteError(error.response.data) as Error)
            }
            setAlert('Não foi possível salvar correção da prova')
        }
        setSaving(false)
    }

    return (
        <div>
            <Dialog
                maxWidth='md'
                fullWidth={true}
                onClose={hidden}
                aria-labelledby="customized-dialog-title"
                open={show}>
                <DialogTitle id="customized-dialog-title" onClose={hidden}>
                    {nomeInstituicao ? (prova?.vestibular?.nome ? (nomeInstituicao + ' - ' + prova.vestibular.nome) : nomeInstituicao) : (prova?.vestibular?.nome ? prova.vestibular.nome : '')}
                    {nomeInstituicao || prova?.vestibular?.nome ? <br /> : ''}
                    Avaliação de Prova {`${prova.tipo ? `(${prova.tipo})` : ''}`}
                    <br />
                    <small>
                        {`Aluno: ${prova?.aluno?.nome} | CPF: ${prova?.aluno?.cpf}`}
                    </small>
                    <br />
                    <small>
                        Curso: {prova?.curso?.nome}
                    </small>
                </DialogTitle>
                <DialogContent dividers>
                    {alert.length > 0 &&
                        <Alert severity="error" style={{ marginBottom: 10 }}>
                            {alert}
                        </Alert>}
                    <Button
                        href={`${process.env.REACT_APP_API_URL}/files/alunos/${prova?.aluno?.id}/provas/${prova?.path}`}
                        variant="outlined"
                        target='_blank'
                    >
                        Download da prova
                    </Button>
                    <Grid container>
                        <Grid  >
                            <TextField
                                value={pontos}
                                onChange={({ target }) => setPontos(target.value)}
                                variant="outlined"
                                margin="normal"
                                label="Pontuação"
                                type="number"
                                helperText={erros.pontos || ''}
                                error={!!erros.pontos}
                            />
                        </Grid>
                        <Grid >
                            <FormControl
                                margin="normal"
                                className={classes.formControl}
                                variant="outlined"
                                style={{ minWidth: 200, marginTop: 15 }}>
                                <InputLabel id="demo-simple-select-outlined-label">Resultado *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    label='Resultado *'
                                    required
                                    margin='none'
                                    error={!!erros.resultado}
                                    value={resultado}
                                    onChange={({ target }) => setResultado(target.value)}
                                >
                                    <MenuItem value={'A'}>Aprovado</MenuItem>
                                    <MenuItem value={'R'}>Reprovado</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {resultado && resultado == 'R' &&
                            <Grid >
                                <FormControl
                                    margin="normal"
                                    className={classes.formControl}
                                    variant="outlined"
                                    style={{ minWidth: 300, marginTop: 15 }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Motivo *</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        label='Motivo *'
                                        required
                                        margin='none'
                                        error={!!erros.motivo_reprovacao_id}
                                        value={motivo_reprovacao_id}
                                        onChange={({ target }) => setMotivo_reprovacao_id(target.value)}
                                    >
                                        {motivosReprovacao.map((item: { id: number, nome: string }) => (
                                            <MenuItem value={item.id}>{item.nome}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <TextField
                        onChange={onChangeHandler}
                        variant="outlined"
                        margin="normal"
                        label="Arquivo da correção"
                        type="file"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={erros.file || ''}
                        error={!!erros.file}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={saving}
                        variant="contained"
                        type='button'
                        onClick={handleSave}
                        color="primary">
                        Enviar Avaliação
                        {saving && <CircularProgress size={24} />}
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={saving} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default AvaliarProva;