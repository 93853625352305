import React, { createContext, useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider as UiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { themePreferenceStorageKey } from '../utils'

type ThemeTypes = "light" | "dark" | undefined

interface IThemeContextData {
    mode: ThemeTypes,
    change(mode: ThemeTypes): void
}

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData)

export const ThemeProvider: React.FC<{}> = ({ children }) => {

    const [mode, setMode] = useState<ThemeTypes>('dark')


    useEffect(() => {
        loadTheme()
    }, [])

    useEffect(() => {
        localStorage.setItem(themePreferenceStorageKey, mode as string)
    }, [mode])

    function change(mode: ThemeTypes) {
        setMode(mode)
    }

    function loadTheme() {
        let localTheme = localStorage.getItem(themePreferenceStorageKey)
        if (localTheme) {
            setMode(localTheme as ThemeTypes)
        } else {
            localStorage.setItem(themePreferenceStorageKey, 'dark')
            setMode('dark' as ThemeTypes)
        }
    }

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: mode,
                    primary: { main: '#4CAF50' },
                    secondary: { main: '#2196F3' },

                },
            }),
        [mode],
    );

    return (
        <UiThemeProvider theme={theme}>
            <ThemeContext.Provider
                value={{
                    mode,
                    change
                }}
            >
                <CssBaseline />
                {children}
            </ThemeContext.Provider>
        </UiThemeProvider>
    );
}

export default ThemeContext;