import React, { useEffect, useState } from 'react';
import api from '../../../services/api'
import Pagination from '@material-ui/lab/Pagination';
import { Alert } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Filters from './Filters'
import {
  CardContent,
  CardActionArea
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Prova, Aluno } from '../../../models/academic'

import AvaliarProva from './Avaliar'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


interface PageProperties {
  total: number,
  perPage: number,
  page: number,
  lastPage: number,
  data: Array<Prova>
}

interface IProvas {
  nomeInstituicao?: string,
  sistemaAtivo?: string,
  loadSistemaAtivo(): Promise<void>
}

const Provas: React.FC<IProvas> = ({ nomeInstituicao, sistemaAtivo, loadSistemaAtivo }) => {

  const classes = useStyles();
  const theme = useTheme()
  const [pageProperties, setPageProperties] = useState<PageProperties>({} as PageProperties)
  const [page, setPage] = useState<number>(1)
  const [error, setError] = useState<string>('')
  const [provaEmAvaliacao, setProvaEmAvaliacao] = useState<Prova>({} as Prova)
  const [tipo, setTipo] = useState<string>('')
  const [avaliacao, setAvaliacao] = useState<string>('false')

  useEffect(() => {
    load(tipo, avaliacao)
  }, [page, tipo, avaliacao])


  async function load(tipo?: string, avaliados?: string) {
    try {
      const params = { page }
      //@ts-ignore
      if (tipo && tipo.length > 0) params.tipo = tipo
      //@ts-ignore
      if (avaliados && avaliados.length > 0) params.avaliados = avaliados

      const response = await api.get('/provas', {
        params
      })
      const data = await response.data
      setPageProperties(data as PageProperties)

      loadSistemaAtivo()
    } catch (error) {
      setError('Falha na busca')
      setTimeout(() => setError(''), 1000)
    }
  }

  function handleChangePage(e: object, pageSelected: number) {
    setPage(pageSelected)
  }

  function handleAvaliarProva(prova: Prova) {
    setProvaEmAvaliacao(prova)
  }

  function cardColor(prova: Prova): string | undefined {
    if (prova.tipo === 'candidato') {
      return theme.palette.primary.dark
    } else if (prova.tipo === 'simulado') {
      return theme.palette.info.dark
    }
    return undefined
  }

  async function handleFiltersChange(
    tipo: string,
    avaliados: string): Promise<void> {
    setTipo(tipo)
    setAvaliacao(avaliados)
  }


  return (<>
    {
      error.length > 0 &&
      <Alert severity="error">{error}</Alert>
    }
    <AvaliarProva
      prova={provaEmAvaliacao}
      show={!!provaEmAvaliacao.id}
      onSuccess={load}
      hidden={() => setProvaEmAvaliacao({} as Prova)}
      nomeInstituicao={nomeInstituicao}
    />
    <h1>Provas</h1>
    <Filters
      onChange={handleFiltersChange}
    />
    <Pagination
      count={pageProperties.lastPage}
      page={page}
      onChange={handleChangePage}
      color="primary" />
    <div style={{ minHeight: 200, paddingTop: 12 }}>

      {!(!sistemaAtivo || sistemaAtivo === 'false') ?

        pageProperties && pageProperties.data && pageProperties.data.length > 0 ?

          pageProperties?.data?.map((prova, key) => (
            <Card key={key} variant="outlined" style={{ marginBottom: 12, backgroundColor: cardColor(prova) }}>
              <CardActionArea
                onClick={() => handleAvaliarProva(prova)}
              >
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {prova.vestibular.nome}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {prova.curso.nome}
                  </Typography>
                  <br />
                  <Typography variant="body2" component="p">
                    {prova.resultado ? (prova.resultado === 'R' ? 'Reprovado' : 'Aprovado') : null}
                    <br />
                    {`${prova.aluno.nome} ${prova.tipo ? `(${prova.tipo})` : ''}`}
                    <br />
                    {prova.aluno.cpf}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))

          :

          <Typography variant="body2" component="p">
            <br />
            {'Nenhum item encontrado!'}
            <br />
            <br />
            {` `}
          </Typography>

        :

        <Typography variant="body2" component="p">
          <br />
          {'Bloqueio no carregamento de Provas!'}
          <br />
          <br />
          {`Entre em contato com a administradora do sistema: (contato@growbit.com)`}
        </Typography>
      }
    </div>
  </>
  )
}

export default Provas;