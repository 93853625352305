import React, { useState, useEffect, useContext } from 'react';
import api from '../../../services/api'
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AuthContext from '../../../context/Auth'
import ThemeContext from '../../../context/Theme'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu, Icon } from '@material-ui/core';


import Provas from '../../../pages/Academic/Provas'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default function Admin() {

  const classes = useStyles();
  const { user, signout } = useContext(AuthContext)
  const { change, mode } = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const [nomeInstituicao, setNomeInstituicao] = useState<string>('')
  const [sistemaAtivo, setSistemaAtivo] = useState<string>('')
  
  const [error, setError] = useState<string>('')

  useEffect(() => {
    loadNomeInstituicao()
    loadSistemaAtivo()
  }, [])

  const handleSignout = () => {
    signout()
  };

  const handlePerfil = () => {
    // alert('PERFIL ACIONADO')
    //handleClose()
    setError('Edição de Perfil ainda não implementada para a sua instituição!')
    handleClose()
    setTimeout(() => setError(''), 4000)
  };

  const handleChangeTheme = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await change(mode === 'dark' ? 'light' : 'dark')
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function loadNomeInstituicao() {
    try {
      const response = await api.get('/parametros/nome_instituicao');
      const data = await response.data
      console.log({ data })

      if (data && data.id > 0)
        setNomeInstituicao(data.valor)
    } catch (error) {
      setError('Falha na busca da Instituição')
      setTimeout(() => setError(''), 1000)
    }
  }

  async function loadSistemaAtivo() {
    try {
      const response = await api.get('/parametros/sistema_ativo');
      const data = await response.data
      console.log({ data })

      if (data && data.id > 0)
        setSistemaAtivo(data.valor)
    } catch (error) {
      setSistemaAtivo('false');
      setError('Falha na busca da liberação do sistema')
      setTimeout(() => setError(''), 1000)
    }
  }

  return (

    <div className={classes.root}>
      {
        error.length > 0 &&
        <Alert severity="error">{error}</Alert>
      }
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            {nomeInstituicao && nomeInstituicao.length > 0 ? `IVEST - ${nomeInstituicao}` : `IVEST`}
          </Typography>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handlePerfil}>Perfil</MenuItem>
              <MenuItem onClick={handleSignout}>Sair</MenuItem>
            </Menu>
            {/* <Switch
              checked={mode === 'dark'}
              onChange={handleChangeTheme}
              aria-label="login switch" /> */}
          </div>

        </Toolbar>
      </AppBar>
      <Container maxWidth="md">
        <Provas
          nomeInstituicao={nomeInstituicao}
          sistemaAtivo={sistemaAtivo}
          loadSistemaAtivo={loadSistemaAtivo}
        />
      </Container>
    </div>
  );
}
