export const userStorageKey = '@ivest-user'
export const authStorageKey = '@ivest-auth'
export const themePreferenceStorageKey = '@ivest-theme-preference'

interface IResponseErrorItem {
    message: string,
    field: string,
    validation: string
}

export const isAuthenticated = () => localStorage.getItem(authStorageKey) !== null;

export function bindRemoteError(response: Array<IResponseErrorItem>): object {
    try {
        const obj = {} as any
        response.map(item => obj[item.field] = item.message)
        return obj
    } catch (error) {
        console.log(error)
    }
    return {}
}