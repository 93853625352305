import axios from 'axios';
import { authStorageKey } from "../utils";
import Auth from '../models/security/Auth'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.defaults.timeout = 60 * .3 * 1000; // 30 sec

api.interceptors.request.use(async config => {
  let auth = localStorage.getItem(authStorageKey)
  if (auth) {
    const authObj = JSON.parse(auth) as Auth
    config.headers.Authorization = `Bearer ${authObj.token}`;
  }
  return config;
});


export default api;