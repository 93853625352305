import React, { useState, useEffect, useContext, MouseEvent } from 'react';
import AuthContext from '../../../context/Auth'
import { bindRemoteError } from '../../../utils'
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

type Error = {
    email?: string
    password?: string
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {

    const history = useHistory()
    const theme = useTheme()
    const classes = useStyles(theme);
    const { signin, loading, signed } = useContext(AuthContext)

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [erros, setErrors] = useState<Error>({} as Error)
    const [alert, setAlert] = useState<string>('')

    useEffect(() => {
        if (signed) history.push('/admin')
    }, [signed])

    useEffect(() => {
        setErrors({
            ...erros,
            email: undefined
        })
    }, [email])

    function showAlert(msg: string): void {
        setAlert(msg)
        setTimeout(() => setAlert(''), 5000)
    }

    useEffect(() => {
        setErrors({
            ...erros,
            password: undefined
        })
    }, [password])

    async function handleEntrar(e: MouseEvent) {
        e.preventDefault()
        try {
            await signin(email, password)
        } catch (error) {
            //@ts-ignore
            if (error.response)
                //@ts-ignore
                setErrors(bindRemoteError(error.response.data) as Error)
            showAlert('Não foi possível realizar login')
        }
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {
                alert.length > 0 &&
                <Alert severity="error">
                    <AlertTitle>Atenção</AlertTitle>
                    {alert}
                </Alert>
            }
            <div className={classes.paper}>
                <Avatar className={classes.paper}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
                    <TextField
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        helperText={erros.email || ''}
                        error={!!erros.email}
                    />
                    <TextField
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        helperText={erros.password || ''}
                        error={!!erros.password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleEntrar}
                        className={classes.submit}
                    >
                        Entrar
                    </Button>
                    <Grid container>
                        {/* <Grid item xs>
                            <Link href="#" variant="body2">
                                Esqueceu a senha?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Não tem conta? Cadastre-se"}
                            </Link>
                        </Grid> */}
                    </Grid>
                </form>
            </div>
        </Container>
    );
}