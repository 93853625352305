import React, { useContext, ElementType, useEffect } from "react";
import AuthContext from '../context/Auth'
import Admin from "../components/layouts/Admin";
// react library for routing
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
    RouteProps,
    useHistory
} from "react-router-dom";

import SignIn from '../pages/Auth/SignIn'

const PrivateRoute: React.FC<RouteProps & { signed: boolean }> = ({ component, signed, ...rest }) => {

    const Component = component as ElementType

    useEffect(() => {
        console.log(signed)
    }, [signed])

    return (<Route
        {...rest}
        render={props =>
            signed ? (
                <Component {...props} />
            ) : (
                    <Redirect to={{ pathname: "/auth/signin", state: { from: props.location } }} />
                )
        }
    />
    )
}


const App = () => {

    const { signed } = useContext(AuthContext)
    const history = useHistory()

    useEffect(() => {
        console.log(signed)
    }, [signed])

    return (<BrowserRouter>
        <Switch>
            <PrivateRoute signed={signed} path="/admin" component={Admin} />

            <Route path="/admin/" component={() => <h1>Entrou</h1>} />
            <Route path="/auth/signin" exact component={SignIn} />

            <Redirect from="/auth" to="/auth/signin" />
            <Redirect from="/" to="/admin" />
            <Route component={() => <h1>Nada</h1>} />

        </Switch>
    </BrowserRouter>
    );
}
export default App;