import React, { useState, useEffect, createContext } from 'react';
import api from '../services/api'
import User from "../models/security/User";
import { AxiosResponse } from 'axios';
import { userStorageKey, authStorageKey } from "../utils";

import {
    Backdrop,
    CircularProgress
} from '@material-ui/core';

interface IAuthContextData {
    user: User | undefined,
    auth: object | undefined,
    signed: boolean,
    signin(email: string, password: string): Promise<void>,
    signout(): Promise<void>,
    loading: boolean,
}

interface IAuthProvider {

}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData)

export const AuthProvider: React.FC<IAuthProvider> = ({ children, ...props }) => {

    const [user, setUser] = useState<User | undefined>(undefined)
    const [auth, setAuth] = useState<object | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        loadStorageData()
    }, [])


    async function signin(email: string, password: string) {
        const response = await api.post('/sessions', {
            email,
            password,
        })

        await saveAuth(response)
    }

    async function saveAuth(response: AxiosResponse): Promise<void> {
        const { user, auth } = await response.data
        localStorage.setItem(userStorageKey, JSON.stringify(user))
        localStorage.setItem(authStorageKey, JSON.stringify(auth))

        setUser(user)
        setAuth(auth)
    }

    async function loadStorageData(): Promise<void> {
        setLoading(true)
        const storagedUser = localStorage.getItem(userStorageKey)
        const storagedAuth = localStorage.getItem(authStorageKey)

        if (storagedUser && storagedAuth) {
            setUser(JSON.parse(storagedUser))
            setAuth(JSON.parse(storagedAuth))
        }
        await checkSession()
        await new Promise((resolve) => setTimeout(resolve, 1000))
        setLoading(false)
    }

    async function checkSession(): Promise<void> {
        try {
            await api.get('/sessions')
        } catch (error) {
            //@ts-ignore
            if (error.response && error.response.status === 401) {
                await signout()
            }
        }
    }

    async function signout() {
        sessionStorage.clear()
        localStorage.clear()
        setUser(undefined)
        setAuth(undefined)
    }


    if (loading) return (<Backdrop open>
        <CircularProgress color="inherit" />
    </Backdrop >)

    return (
        <AuthContext.Provider value={{
            user,
            signin,
            signout,
            auth,
            signed: user != undefined,
            loading
        }}>
            {children}
        </AuthContext.Provider>
    )
}


export default AuthContext;