import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Container } from './styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface IFilters {
    onChange(tipo: string | undefined, avaliados: string | undefined): Promise<void>
}

const Filters: React.FC<IFilters> = ({ onChange }) => {

    const classes = useStyles();
    const [tipo, setTipo] = useState<string>('')
    const [avaliacao, setAvaliacao] = useState<string>('false')

    useEffect(() => {
        onChange(tipo, avaliacao)
    }, [tipo, avaliacao])

    return (
        <Container>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={tipo}
                    onChange={({ target }) => setTipo(target.value as string)}
                >
                    <MenuItem value="">
                        <em>Todos</em>
                    </MenuItem>
                    <MenuItem value={'candidato'}>Candidato</MenuItem>
                    <MenuItem value={'simulado'}>Simulado</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Avaliação</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={avaliacao}
                    onChange={({ target }) => setAvaliacao(target.value as string)}
                >
                    <MenuItem value="">
                        <em>Todos</em>
                    </MenuItem>
                    <MenuItem value={'true'}>Somente provas avaliadas</MenuItem>
                    <MenuItem value={'false'}>Somente provas não avaliadas</MenuItem>
                </Select>
            </FormControl>
        </Container>
    )
}

export default Filters;